<template>
  <div>
    <b-tabs class="tabs__cashier mt-0" style="position: relative">
      <b-tab
        id="test-tour"
        active
        title="Terakhir Dipilih"
        @click="
          (filters.only_favorite = 0), (filters.sort_by = 'selected_count')
        "
      >
        <div v-if="isLoading" class="text-center">
          <b-spinner variant="primary" />
        </div>
        <div v-else>
          <b-card
            v-for="(item, index) in result.data"
            :key="index"
            no-body
            class="mb-1 card__product"
            style="border-radius: 1rem"
          >
            <CardProduct
              :firstIndex="index"
              :id-edit="id"
              :is-loading="isLoadingEdit"
              :get-data="getData"
              :detail-product="detailProduct"
              :products="item"
              :is_favorite="item.is_favorite"
            />
          </b-card>
        </div>
        <div v-if="result.data && result.data.length == 0 && !isLoading">
          <h6>Favorite produk tidak ditemukan</h6>
        </div>
      </b-tab>
      <b-tab title="Favorit" @click="filters.only_favorite = 1">
        <div v-if="isLoading" class="text-center">
          <b-spinner variant="primary" />
        </div>
        <div v-else>
          <b-card
            v-for="(item, index) in result.data"
            :key="index"
            no-body
            class="mb-1 card__product"
            style="border-radius: 1rem"
          >
            <CardProduct
              :id-edit="id"
              :is-loading="isLoadingEdit"
              :get-data="getData"
              :detail-product="detailProduct"
              :products="item"
              :is_favorite="item.is_favorite"
            />
          </b-card>
        </div>
        <div v-if="result.data && result.data.length == 0 && !isLoading">
          <h6>Favorite produk tidak ditemukan</h6>
        </div>
      </b-tab>
      <div
        class="filter__container"
        :class="{ 'd-none': filters.only_favorite == 0 }"
      >
        <b-button
          class="bg-transparent"
          :class="{
            'opacity-1': filters.only_favorite == 1,
            active: filter == 'populer',
          }"
          @click="
            (filters.sort_by = 'selected_count'),
              (filter = 'populer'),
              (filters.sort_type = '')
          "
          id="button--popular"
        >
          Populer
        </b-button>
        <b-button
          class="bg-transparent"
          :class="{
            'opacity-1': filters.only_favorite == 1,
            active: filter == 'a-z',
          }"
          @click="
            (filters.sort_by = ''),
              filters.sort_type == 'asc'
                ? (filters.sort_type = 'desc')
                : (filters.sort_type = 'asc'),
              (filter = 'a-z')
          "
          id="button--a__z"
        >
          A-Z
        </b-button>
      </div>
    </b-tabs>
    <ModalEditProduct
      :key="2"
      :modal-key="'product'"
      :result="resultDetail"
      :form-data="formPayload"
      :create-item-to-cart="createItemToCart"
      :messages="messages"
      :loading="isLoading"
      :stock-warehouse="stock"
      :type-warehouses="typeWarehouse"
      @typeWarehouse="getTypeWarehouse"
      @payload="getPayload"
    />
  </div>
</template>

<script>
import { BTabs, BTab, BCard, BButton, BSpinner } from "bootstrap-vue";
import ModalEditProduct from "@/components/Cashier/Modal/AddProductToCart.vue";
import CardProduct from "@/components/Cashier/Card/CardProduct.vue";
import {
  checkPermission,
  successNotification,
  errorNotification,
  preformatFloat,
} from "@/auth/utils";

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    BButton,
    CardProduct,
    BSpinner,
    ModalEditProduct,
  },
  props: {
    getProfiles: {
      type: Function,
      required: true,
    },
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
      preformatFloat,
    };
  },
  data() {
    return {
      isLoading: false,
      isLoadingEdit: false,
      is_favorite: false,
      filter: "populer",
      result: Object,
      filters: {
        only_favorite: 0,
        sort_by: "selected_count",
        sort_type: "desc",
      },
      formPayload: {
        delivery_numbers_uuid: [],
        po_so_uuid: "",
        product_uuid: "",
        warehouse_uuid: "",
        qty: 0,
        price: 0,
        discounts: [],
        is_bonus: 0,
      },
      resultDetail: {},
      messages: "",
      id: "",
      typeWarehouse: "",
      stock: 0,
      userData: JSON.parse(localStorage.getItem("userData")),
    };
  },
  watch: {
    filters: {
      handler() {
        this.getData();
      },
      deep: true,
    },
    "$store.state.cashier.userData": {
      handler(value) {
        this.userData = value;
      },
      deep: true,
    },
  },
  created() {
    this.getData();
  },
  mounted() {
    this.userData = JSON.parse(localStorage.getItem("userData"));
  },
  methods: {
    getTypeWarehouse(value) {
      this.typeWarehouse = value;
    },
    getPayload(value) {
      this.formPayload = value;
    },
    async createItemToCart() {
      if (this.formPayload.warehouse_uuid === undefined) {
        errorNotification(
          this,
          "Oops!",
          "Terjadi kesalahan. Coba refresh halaman ini lagi"
        );
        this.isLoading = false;
      } else {
        this.isLoading = true;
        this.formPayload.qty =
          typeof this.formPayload.qty === "number"
            ? parseFloat(this.formPayload.qty).toString().replace(".", ",")
            : this.formPayload.qty;
        if (this.$store.state.cart.isEdit === false) {
          this.formPayload.product_uuid = this.id;
        }
        if (this.typeWarehouse === "warehouse") {
          delete this.formPayload.po_so_uuid;
          delete this.formPayload.delivery_numbers_uuid;
        }
        if (!this.typeWarehouse) {
          delete this.formPayload.po_so_uuid;
          delete this.formPayload.delivery_numbers_uuid;
        }
        const form = this.preparePayload();
        await this.$store
          .dispatch(
            `cart/${
              this.$store.state.cart.isEdit === true
                ? "postEditItemToCart"
                : "postAddItemToCart"
            }`,
            {
              uuid:
                this.$store.state.cart.isEdit === false
                  ? this.$store.state.cart.cartUuid
                  : this.$store.state.cart.itemUuid,
              payload: form,
            }
          )
          .then(() => {
            successNotification(
              this,
              "Success",
              `Produk berhasil ${
                this.$store.state.cart.isEdit === false
                  ? "ditambahkan"
                  : "diubah"
              } ke cart`
            );
            this.getActiveCart();
            this.isLoading = false;
            this.formPayload = {};
            this.getProfiles();
            this.$bvModal.hide("modal-add-product-to-cart-product");
            this.$store.commit("cart/setIsEdit", false);
            if (!this.userData.finish_tutorial) {
              setTimeout(() => {
                this.$tours.cashierTour3.start();
              }, 500);
            }
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            this.isLoading = false;
            this.messages = err.response.data.meta.messages;
          });
      }
    },
    async detailProduct(id) {
      this.$tours.cashierTour1.stop();
      this.isLoadingEdit = true;
      this.$store.commit("cart/setIsEdit", false);
      this.formPayload.product_uuid = id;
      this.id = id;
      this.messages = "";
      await this.$store
        .dispatch("cashier/getData", {
          uuid: `/${id}`,
          params: {
            with_delivery_numbers: 1,
          },
        })
        .then((result) => {
          const item = result.data.data;
          this.resultDetail = item;
          this.formPayload = {
            is_bonus: 0,
            price: item.standard_price,
            discounts: item.discounts,
            qty: 0,
            warehouse_uuid:
              item.stock_in_warehouse.length > 0
                ? item.stock_in_warehouse[0].uuid
                : "",
          };
          this.stock =
            item.stock_in_warehouse.length > 0
              ? item.stock_in_warehouse[0].stock
              : 0;
          this.typeWarehouse =
            item.stock_in_warehouse.length > 0
              ? item.stock_in_warehouse[0].type
              : "";
          this.isLoadingEdit = false;
          this.$bvModal.show("modal-add-product-to-cart-product");
          if (!this.userData.finish_tutorial) {
            setTimeout(() => {
              this.$tours.cashierTour2.start();
            }, 500);
          }
        })
        .catch((err) => {
          this.isLoadingEdit = false;
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    async getActiveCart() {
      await this.$store
        .dispatch("cart/getCart", {
          uuid: this.$route.query.uuid || "",
          params: "",
        })
        .then((result) => {
          const item = result.data.data;
          this.$store.commit("cart/setDiscountCart", item.discount_value);
          this.$store.commit("cart/setDiscountType", item.discount_type);
          this.$store.commit("cart/setDiscountTotal", item.discount_total);
          this.$store.commit("cart/setSubtotalCart", item.subtotal);
          this.$store.commit("cart/setTotalPrice", item.total);
          this.$store.commit("cart/setTotalBuy", item.items.length);
          this.$store.commit("cart/setCartUuid", item.uuid);
          this.$store.commit("cart/setResultCartActive", item);
          this.$store.commit("cashier/setProduct", item.items.length);
          localStorage.setItem("cartUuid", item.uuid);
          localStorage.setItem("cash_total_payment", item.total);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    preparePayload() {
      const form = new FormData();
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        // eslint-disable-next-line no-prototype-builtins
        if (
          this.formPayload.hasOwnProperty(key) &&
          this.formPayload[key] != null
        ) {
          if (
            key !== "price" &&
            key !== "qty" &&
            key !== "discounts" &&
            key !== "delivery_numbers_uuid" &&
            key !== "warehouse_uuid" &&
            key !== "supplier"
          ) {
            form.append(key, this.formPayload[key]);
          }
          if (key === "price" || key === "qty") {
            // eslint-disable-next-line radix
            form.append(
              key,
              parseFloat(this.preformatFloat(this.formPayload[key].toString()))
            );
          }
        }
      }
      if (this.typeWarehouse === "warehouse") {
        form.append("warehouse_uuid", this.formPayload.warehouse_uuid);
      }
      if (this.typeWarehouse === "supplier") {
        form.append("supplier_uuid", this.formPayload.warehouse_uuid);
      }
      for (let index = 0; index < this.formPayload.discounts.length; index++) {
        const element = this.formPayload.discounts[index];
        // eslint-disable-next-line no-restricted-syntax
        for (const key in element) {
          if (Object.hasOwnProperty.call(element, key)) {
            const items = element[key];
            form.append(`discounts[${index}][${key}]`, items);
          }
        }
      }
      if (this.formPayload.delivery_numbers_uuid) {
        for (
          let index = 0;
          index < this.formPayload.delivery_numbers_uuid.length;
          index++
        ) {
          const element = this.formPayload.delivery_numbers_uuid[index];
          form.append("delivery_numbers_uuid[]", element.label);
        }
      }
      return form;
    },
    async getData() {
      if (this.filters.only_favorite === 0) {
        delete this.filter.sort_by;
        delete this.filter.sort_type;
      }
      this.isLoading = true;
      const queryParams = this.filters;
      queryParams.with_delivery_numbers = 1;
      await this.$store
        .dispatch("cashier/getData", {
          uuid: "",
          params: queryParams,
        })
        .then((result) => {
          this.result = result.data.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";

/* ===================== LAST PRODUCT SELECTED ====================*/
.filter__container {
  position: absolute;
  top: 10px;
  right: 0;
  display: flex;
  z-index: 10;

  .btn.bg-transparent {
    border-color: transparent !important;
    background-color: transparent !important;
    color: #a5a9bb !important;
    transition: opacity 0.5s, color 0.5s;
    opacity: 0;
    position: relative;
    font-weight: 700;

    &.active {
      color: $dark !important;
      font-weight: 700;

      &::after {
        content: "";
        position: absolute;
        bottom: -3vh;
        height: 3px;
        transition: transform 0.3s;
        width: 70%;
        background: #333348 !important;
        border: 2px solid #333348;
        border-radius: 4px 4px 0px 0px;
        left: 0;
        margin-left: 15%;
      }
    }

    &.opacity-1 {
      opacity: 1;
    }

    &:hover {
      box-shadow: none !important;
    }
  }
}

.tabs.tabs__cashier {
  .nav-tabs {
    background-color: #eff1f5;
    padding: 0 1.5rem;
    padding-top: 1rem;
    margin-bottom: 0;

    .nav-link {
      padding: 0.61rem 0.61rem 2rem 0.7rem;
      color: #a5a9bb;
      font-weight: 800;

      &.active {
        color: $dark;
      }

      &::after {
        background: #333348 !important;
        width: 70%;
        margin-left: 15%;
        border: 2px solid #333348;
        border-radius: 4px 4px 0px 0px;
      }
    }
  }

  .tab-content {
    padding: 2rem 1.5rem;
    background: #f6f7fa;
    overflow-y: scroll;
    height: calc(100vh - 152.61px);

    @media screen and (min-width: 992px) {
      height: calc(100vh - 155px);
    }

    .tab-pane {
      .card.card__product {
        box-shadow: none;
      }
    }
  }

  .favorite__box {
    width: 32px;
    height: 32px;
    border: 0.7px solid #a5a9bb;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image__container {
    margin-right: 8px;

    img {
      width: 69px;
      height: 83px;
      object-fit: cover;
      border-radius: 8px;

      @media screen and (max-height: 800px) {
        width: 64px;
      }
    }
  }
}
</style>
